import React, { useState } from "react";

const RegisterComponent = ({ modAuthTrue }) => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault(); // Evitar el comportamiento predeterminado del formulario
    setError("");
    setSuccess("");

    try {
      const response = await fetch("http://93.127.163.108:8888/register", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ username, email, password }),
      });

      const data = await response.json();

      if (response.ok) {
        setSuccess(data.message); // Mensaje de éxito
        window.location.replace('/'); // Cambiar el estado de autenticación si es necesario
        // Aquí puedes redirigir al usuario o hacer más acciones si es necesario
      } else {
        setError(data.message); // Mensaje de error
      }
    } catch (err) {
      setError("Error: " + err.message);
    }
  };

  return (
    <div className="register-container" style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
      <h2>Sign up</h2>
      <form className="register-container-form" onSubmit={handleSubmit} style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
        <div>
          <label>Username:</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit">submit</button>
      </form>

      {error && <div style={{ color: "red" }}>{error}</div>}
      {success && <div style={{ color: "green" }}>{success}</div>}
    </div>
  );
};

export default RegisterComponent;