import React, { useState, useEffect, useMemo } from "react";
import Modal from "react-modal"; // Import react-modal
import "../../index.css";
import { MaterialReactTable } from "material-react-table";
import Button from "@mui/material/Button";
import loadingGif from "../../loading.gif";
import loadingOrderGif from "../../loadingOrder.gif";
import ProductInfoModal from "../ProductInfoModal/ProductInfoModal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "50%", // Adjust width as needed
  },
};

function Dashboard({ setOrderSummary, orderSummary, modAuthFalse }) {
  const [allData, setAllData] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [modalOrderIsOpen, setModalOrderIsOpen] = useState(false);
  
  const [confirmOrderLoader, setConfirmOrderLoader] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productModalIsOpen, setProductModalIsOpen] = useState(false);

  const handleConfirmOrder = async () => {
    setConfirmOrderLoader(true); // Display loader
    closeModal();
    const token = localStorage.getItem('token'); // Get JWT token from localStorage
    console.log({ token });
    try {
      const response = await fetch("http://93.127.163.108:8888/orders", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Add token to authorization header
        },
        body: JSON.stringify({
          orders: orderSummary, // Send order summary
        }),
      });

      if (response.ok) {
        alert("Orders created successfully, you will receive a copy via email.");
        setRowSelection({});
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message || "Unknown error");
      }
    } catch (error) {
      console.error("Error creating orders:", error.message);
      if (error.message.includes("401")) {
        alert("Invalid session. Please log in again.");
      } else {
        alert("There was an error creating the orders. Please try again.");
      }
    } finally {
      setConfirmOrderLoader(false); // Hide the loader regardless of the result
    }
  };

  

  const closeModal = () => {
    setModalOrderIsOpen(false);
  };

  const openProductModal = (product) => {
    setSelectedProduct({ ...product, quantity: product.quantity || 0 });
    setProductModalIsOpen(true);
  };

  const closeProductModal = () => {
    setProductModalIsOpen(false);
    setSelectedProduct(null);
  };

  const handleAddToOrder = (product) => {
    // Verificar si el producto ya existe en orderSummary
    const existingProduct = orderSummary.find(item => item.Strain === product["Strain"]);
  
    if (existingProduct) {
      // Si el producto ya está en orderSummary, solo actualizamos la cantidad
      setOrderSummary((prevSummary) =>
        prevSummary.map((item) =>
          item.Strain === product["Strain"]
            ? { ...item, quantity: item.quantity + (product.quantity || 0) } // Sumar la cantidad
            : item
        )
      );
    } else {
      // Si el producto no está, lo agregamos a orderSummary
      setOrderSummary((prevSummary) => [
        ...prevSummary,
        {
          batchID: product["Batch ID"],
          strain: product.Strain,
          cycle: product.Cycle,
          quantity: product.quantity || 0, // Usar la cantidad ingresada
          stock: product["US & Canada"],
        },
      ]);
    }
    alert('product added to order')
    closeProductModal(); // Cerrar el modal después de agregar
  };

  const handleQuantityChange = (event, product) => {
    const value = parseInt(event.target.value, 10);
  
    // Actualizar la cantidad en el estado
    setAllData((prevData) =>
      prevData.map((item) =>
        item === product ? { ...item, quantity: value } : item
      )
    );
  };

  const columns = useMemo(() => {
    const muiTableHeadCellPropsSettings = {
      sx: {
        fontSize: {
          xs: "10px",
          sm: "11px",
          md: "12px",
          lg: "16px",
          xl: "16px",
        },        
      },
    };
    return [
      
      {
        accessorKey: "Strain",
        header: "Strain",
        muiTableHeadCellProps: muiTableHeadCellPropsSettings,
        Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong>,
        size: 130,
      },
      {
        accessorKey: "Batch ID",
        header: "Batch ID",
        muiTableHeadCellProps: muiTableHeadCellPropsSettings,
        size: 130,
        Cell: ({ row }) => (
          <button
            className="batch-id-button"
            style={{
              background: "black",
              border: "1px solid white",
              color: "white",
              borderRadius: "16px",
            }}
            onClick={() => openProductModal(row.original)}
          >
            <b>
              {row.original["Batch ID"] !== ""
                ? row.original["Batch ID"]
                : "No Batch Id"}
            </b>
          </button>
        ),
      },
      {
        accessorKey: "Cycle",
        header: "Cycle",
        muiTableHeadCellProps: muiTableHeadCellPropsSettings,
        size: 130,
      },
      {
        accessorKey: "Flavor/Aroma Profile",
        header: "Flavor/Aroma",
        muiTableHeadCellProps: muiTableHeadCellPropsSettings,
        size: 130,
      },
      {
        accessorKey: "US & Canada",
        header: "US & Canada",
        size: 130,
        muiTableHeadCellProps: muiTableHeadCellPropsSettings,
      },
      {
        accessorKey: "Europe",
        header: "Europe",
        muiTableHeadCellProps: muiTableHeadCellPropsSettings,
      },
      // Nueva columna para la cantidad
      {
        accessorKey: "quantity",
        header: "Quantity",
        muiTableHeadCellProps: muiTableHeadCellPropsSettings,
        Cell: ({ row }) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <input
              type="number"
              min="0"
              value={row.original.quantity || ''}
              onChange={(e) => handleQuantityChange(e, row.original)}
              onClick={(e) => e.stopPropagation()}
              style={{ width: "80px", marginRight: "8px", color: "black" }}
            />
            <button
            style={{ width: "80px", marginRight: "8px", color: "black" }}
              onClick={(e) => {
                e.stopPropagation()
                
                const product = row.original; // Obtener el producto actual
                
                product.quantity && product.quantity !== '' ? handleAddToOrder(product): alert('please enter a quantity'); // Llama a la función para agregar el producto a la orden
                
              }}
            >
              Add
            </button>
          </div>
        ),
        size: 200,
      }
    ];
  }, [handleAddToOrder]);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        alert("No hay sesión activa. Por favor, inicia sesión nuevamente.");
        return; // Return early if there's no token
      }

      // Verify Session
      const verifySessionUrl = "http://93.127.163.108:8888/verify-session"; // Update with your URL
      try {
        const verifyResponse = await fetch(verifySessionUrl, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        if (!verifyResponse.ok) {
          modAuthFalse();
          throw new Error("Sesión no válida. Por favor, inicia sesión nuevamente.");
        }

        // Proceed to fetch data if session is valid
        const url = "https://fullagro-dataroom.vercel.app/api/fetchData";
        const maxRetries = 3;
        let attempts = 0;
        const token1 = "tpIFuYK3WLt2QxsvGhWrzX9BPzQbJ95jaUtUByQmAIF9kFVX5KWzGCxJPAiZJ9hjWtxchtfJd9rs43qV8jqqpLYYcbfDh2guiKVxGHhRdFOVrg6kMbGPmC0pulSHR5JljgPu9zviAMTcHHAZcFFmQKLfiwEJT5m6rCdv7qfAUulUBBWfOIsChkvOqa6yW8Kmxc1sYCDXkMSof2q2QjYkxsu3Ur0ROA6MUOqsJCb8JBjgz6MsMfcyYdC6mzbc3cfAphczsakY8yzYa5AxaH8iUDvwfpHTIDdKTy2wdLDTFvD8izak0Fwco4P7mYr88l6VVaHhWMc8UHMF2VdeZKe1s5QLplTx6h2kF9kecCTuhtpPsB7r52xDs7KXcfVsqbHf7C2EYbBOOWUEAfMjQzSAePLVLVeT4S4SsoU126iKYZKU0JHeedyP4mEIaQ5mqH2C6yUuE6EAm0j7pCYCgp8hl6D6qQm4K4kTAbEeRQ6DPagVrxLgKhZqw0Q6xyj97JYD";
        while (attempts < maxRetries) {
          try {
            const response = await fetch(url, {
              method: "GET",
              headers: {
                Authorization: `Bearer ${token1}`,
                "Content-Type": "application/json",
              },
            });

            if (!response.ok) {
              if (response.status === 500 || response.status === 502) {
                throw new Error(`Server error: ${response.status}`);
              } else {
                throw new Error("Network response was not ok");
              }
            }

            const data = await response.json();
            setAllData(data.data);
            return; // Exit the loop if successful
          } catch (error) {
            console.error("Hubo un problema con la operación fetch:", error);
            attempts += 1;
            if (attempts >= maxRetries) {
              alert("Something went wrong please try again later");
            } else {
              const delay = Math.pow(2, attempts) * 1000; // Exponential backoff delay
              await new Promise((resolve) => setTimeout(resolve, delay));
            }
          }
        }
      } catch (error) {
        console.error("Error al verificar la sesión:", error);
         // Show error message from session verification
      }
    };

    fetchData();
  }, [modAuthFalse]);

  return (
    <div id="dashboard">
      {confirmOrderLoader && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            zIndex: 9999,
          }}
        >
          <h2>
            <b>Creating Order.</b>
          </h2>
          <img
            src={loadingOrderGif}
            alt="Loading..."
            style={{
              width: "6rem",
              height: "6rem",
            }}
          />
        </div>
      )}
      {allData.length > 0 ? (
        <>
          <h1>Available Products</h1>
         {/*  <Button
            id="order-button"
            variant="contained"
            color="primary"
            onClick={() => orderSummary.length > 0 ?openModal(orderSummary) : alert('No products added')}
          >
            <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m5.50835165 12.5914912c-.00106615-.0057657-.00203337-.011566-.00289985-.0173991l-1.22011509-7.32069058c-.12054699-.72328196-.74633216-1.25340152-1.47959089-1.25340152h-.30574582c-.27614237 0-.5-.22385763-.5-.5s.22385763-.5.5-.5h.30574582c1.1918179 0 2.21327948.84029234 2.44951006 2h16.24474412c.3321894 0 .5720214.31795246.480762.63736056l-2 7.00000004c-.0613288.2146507-.2575218.3626394-.480762.3626394h-12.90976979l.12443308.7465985c.12054699.7232819.74633216 1.2534015 1.47959089 1.2534015h11.30574582c.2761424 0 .5.2238576.5.5s-.2238576.5-.5.5h-11.30574582c-1.22209789 0-2.26507316-.8835326-2.46598481-2.0890025l-.21991747-1.3195048zm-.08478811-6.5914912 1 6h12.69928576l1.7142857-6zm2.57643646 15c-1.1045695 0-2-.8954305-2-2s.8954305-2 2-2 2 .8954305 2 2-.8954305 2-2 2zm0-1c.55228475 0 1-.4477153 1-1s-.44771525-1-1-1-1 .4477153-1 1 .44771525 1 1 1zm9 1c-1.1045695 0-2-.8954305-2-2s.8954305-2 2-2 2 .8954305 2 2-.8954305 2-2 2zm0-1c.5522847 0 1-.4477153 1-1s-.4477153-1-1-1-1 .4477153-1 1 .4477153 1 1 1z"/></svg>
            <b>{orderSummary.length}</b>
          </Button> */}
          <MaterialReactTable
            data={allData}
            columns={columns}
            defaultColumn={{
              maxSize: 200,
              minSize: 10,              
            }}                        
            mrtTheme={(theme) => ({
              baseBackgroundColor: "#000",
            })}
            muiTableBodyRowProps={({ row }) => ({
              onClick: () => openProductModal(row.original),
              sx: {
                cursor: "pointer",
              },
            })}
            muiTableBodyProps={{
              sx: {
                "& td , *": { color: "#fff" },                
              },
            }}
            muiTableHeadProps={{
              sx: {
                zIndex: 1,
                "& *": { color: "#fff" },
              },
            }}
            muiTopToolbarProps={{
              sx: {
                zIndex: 0,
                "& *": { color: "#fff" },
                "& input": { color: "#fff" },
              },
            }}
            muiBottomToolbarProps={{
              sx: {
                zIndex: 0,
                "& *": { color: "#fff" },
              },
            }}
            muiToolbarAlertBannerProps={{
              sx: {
                zIndex: 0,
                "& *": { color: "#fff", backgroundColor: "#000" },
              },
            }}
            muiSearchTextFieldProps= {{
              placeholder: 'Search for anything',              
              variant: 'standard',
            }}         
            positionGlobalFilter="left"   
            enableDensityToggle={false}
            initialState={{ showGlobalFilter: true ,density: "compact", sorting: [
              {
                id: 'Batch ID', //sort by age by default on page load
                desc: true,
              },
              
            ], }}
            
            enableStickyHeader
            enableRowVirtualization
            enableFullScreenToggle={false}
            enableRowSelection={false}
            enableSelectAll={false}            
            enablePagination={false}
            onRowSelectionChange={setRowSelection}
            positionToolbarAlertBanner={"bottom"}            
            state={{ rowSelection }}
            
            
          />
          <ProductInfoModal
            isOpen={productModalIsOpen}
            onRequestClose={closeProductModal}
            product={selectedProduct}
            onAddToOrder={handleAddToOrder}
            productData={allData} // Ensure this prop is passed
          />
          <Modal
            isOpen={modalOrderIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Order Summary"
          >
            <h2>Order Summary</h2>
            {orderSummary && (
              <ul>
                {orderSummary.map((item, index) => (
                  <li key={index}>
                    Batch ID: {item.batchID}, Strain: {item.strain}, Quantity:{" "}
                    {item.quantity}
                  </li>
                ))}
              </ul>
            )}
            <Button onClick={() => handleConfirmOrder()}>Confirm Order</Button>
            <Button onClick={closeModal}>Cancel</Button>
          </Modal>
        </>
      ) : (
        <div>
          <p style={{ color: "white", textAlign: "center" }}>
            <b>Loading Products...</b>
          </p>
          <img
            src={loadingGif}
            alt="Cargando..."
            style={{
              margin: "auto",
              display: "block",
              width: "4rem",
              height: "4rem",
            }}
          />
        </div>
      )}
    </div>
  );
}

export default Dashboard;