import React, { useState, useEffect } from 'react';
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const PurchasedProducts = () => {
    const [purchases, setPurchases] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPurchases = async () => {
            const token = localStorage.getItem('token'); // Asumiendo que el token se guarda en localStorage

            try {
                const response = await fetch("http://93.127.163.108:8888/purchases", {
                    method: "GET",
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                });

                if (!response.ok) {
                    throw new Error("Network response was not ok.");
                }

                const data = await response.json();
                setPurchases(data.purchases); // Asumiendo que la respuesta contiene un arreglo de compras bajo "purchases"
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchPurchases();
    }, []);

    if (loading) {
        return <Typography variant="h6">Cargando productos comprados...</Typography>;
    }

    if (error) {
        return <Typography variant="h6" color="error">Error: {error}</Typography>;
    }

    return (
        <div className="purchases-container">
            <Typography variant="h4" className="purchases-title">Purchased products</Typography>
            {purchases.length > 0 ? (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Batch ID</TableCell>
                                <TableCell>Strain</TableCell>
                                <TableCell>Cycle</TableCell>
                                <TableCell>Quantity</TableCell>
                              
                                
                                <TableCell>Purchased at </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {purchases.map((purchase) => (
                                <TableRow key={purchase.id}>
                                    <TableCell>{purchase.batchID}</TableCell>
                                    <TableCell>{purchase.strain}</TableCell>
                                    <TableCell>{purchase.cycle}</TableCell>
                                    <TableCell>{purchase.quantity}</TableCell>
                                   
                                    <TableCell>{new Date(purchase.created_at).toLocaleString()}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Typography>No se encontraron compras.</Typography>
            )}
        </div>
    );
};

export default PurchasedProducts;